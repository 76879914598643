.content *::selection {
  @apply bg-pink-500;
}

.content {
  @apply flex flex-col flex-1 justify-center relative px-4 mx-auto w-full;
}

.pulse {
  @apply absolute left-0 right-0 top-0 bottom-0 m-auto opacity-20 invisible select-none;

  transform: translateY(-65px);
  width: 400px;
}

.left {
  @apply flex items-center justify-center;
}

.teaser {
  @apply select-none;

  width: 370px;
  mask-image: radial-gradient(72.69% 79.72% at 15.1% 12.68%, #1c1818 0%, rgba(28, 24, 24, 0) 100%);
}

.right {
  @apply flex items-center justify-center;
}

.logo {
  @apply mb-[56px] select-none;
}

.text {
  @apply text-grey-500 mb-2 block ml-1 text-xl relative z-10;
}

.newsletter {
  max-width: 447px;
}

:global(.dark) .pulse {
  @apply opacity-10;
}

:global(.dark) .teaser {
  width: auto;
}

@screen md {
  .content {
    @apply flex-row px-8;
  }

  .pulse {
    @apply visible;
  }

  .left,
  .right {
    @apply w-1/2 flex-1;
  }

  .right {
    @apply justify-start;
  }

  .text {
    @apply whitespace-nowrap;

    line-height: 35px;
    font-size: 25px;
  }

  .box {
    @apply -ml-8 -mt-[20px];
  }
}

@screen lg {
  .content {
    max-width: 1440px;
    padding: 0 55px;
  }
}
