.footer {
  @apply mt-auto py-9 flex flex-col items-center justify-between text-xl px-4 w-full;
}

.footer .link {
  @apply flex items-center transition;
}

.footer-icon {
  @apply ml-2;
}

.footer .link,
.footer-icon {
  @apply text-grey-500 transition;
}

.footer .link:hover,
.footer .link:hover .footer-icon {
  @apply text-pink-500;
}

.product-hunt-icon {
  @apply text-grey-500 transition;
}

.product-hunt-icon:hover {
  @apply text-pink-500;
}

.product-hunt-link {
  @apply mb-10;
}

:global(.dark) .footer .link,
:global(.dark) .footer-icon {
  @apply text-grey-500;
}

:global(.dark) .footer .link:hover,
:global(.dark) .footer .link:hover .footer-icon {
  @apply text-pink-500;
}

@screen md {
  .footer {
    @apply px-8 flex-row;
  }

  .product-hunt-link {
    @apply mb-0;
  }
}

@screen lg {
  .footer {
    @apply mx-auto;

    max-width: 1440px;
    padding-left: 55px;
    padding-right: 55px;
  }
}
